import axios from './index'

class Patient {
    getDoctorHours = params => axios.get('/patient-meeting/doctor-hours', {params})
    findPatient = params => axios.get('/patients/find', {params})
    addMeeting = data => axios.post('/patient-meeting', data)
    updateMeeting = data => axios.post('/patient-meeting/update', data)
    getAllDoctorMeetings = params => axios.get('/patient-meeting/all-doctors', {params})
    getDoctorPatients = params => axios.get('/patient-meeting/doctor', {params})
    delete = id => axios.delete(`/patient-meeting/delete?id=${id}`)
    exportAllMeetingsExcel = params => axios.get('/patient-meeting/all-doctors/export', {
        params,
        responseType:'blob'
    })
    exportDoctorMeetings = params => axios.get('/patient-meeting/doctor/export', {
        params,
        responseType:'blob'
    })
}

export default new Patient()
