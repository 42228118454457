import {Controller, useForm} from 'react-hook-form'
import text from '../../../data/text.json'
import {InputText} from 'primereact/inputtext'
import moment from 'moment'
import {Button} from 'primereact/button'
import {useEffect, useState} from 'react'
import Select from '../../../api/select'
import {Dropdown} from 'primereact/dropdown'

const Filter = ({fetchData}) => {
    const lang = 'az'

    const {control, handleSubmit, reset} = useForm()
    const [loader, setLoader] = useState(false)
    const [doctors, setDoctors] = useState([])

    const filter = async data => {
        setLoader(true)
        for (let d in data) {
            if (data[d] === '' || data[d] === undefined) {
                data[d] = null
            }
        }
        await fetchData({
            ...data,
            start_date: data?.start_date ? moment(data?.start_date).format('YYYY-MM-DD HH:mm:ss') : null,
            end_date: data?.end_date ? moment(data?.end_date).format('YYYY-MM-DD HH:mm:ss') : null
        })
        setLoader(false)
    }

    const fetchDoctors = async() => {
        const data = await Select.getDoctors()
        setDoctors(data)
    }

    useEffect(() => {
        fetchDoctors()
    }, [])

    return (
        <form onSubmit={handleSubmit(filter)} className="grid">
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="name">{text[lang].name}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="name"
                            aria-describedby="name"
                            placeholder={text[lang].name}
                        />
                    </>
                )} name="name" control={control}/>
            </div>
            <div className="col-12 md:col-4">
                <Controller render={({field: {onChange, value}}) => (
                    <>
                        <label htmlFor="surname">{text[lang].surname}</label>
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="surname"
                            aria-describedby="surname"
                            placeholder={text[lang].surname}
                        />
                    </>
                )} name="surname" control={control}/>
            </div>
            <div className="col-12 md:col-4">
                <Controller control={control} render={({field: {value, onChange}}) => (
                    <>
                        <label htmlFor="doctor_id">{text[lang].doctor}</label>
                        <Dropdown
                            value={value}
                            onChange={onChange}
                            options={doctors}
                            className="w-full"
                            name="doctor_id"
                            id="doctor_id"
                        />
                    </>
                )} name="doctor_id"/>
            </div>
            <div className="col-12">
                <div className="flex justify-content-end gap-1">
                    <Button className="p-button-outlined d-flex align-items-center gap-1" type="button"
                        onClick={() => reset()}>
                        <i className="pi pi-replay mr-1"/>
                    </Button>
                    <Button className="p-button-danger d-flex align-items-center gap-1" disabled={loader}>
                        {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                        {text[lang].search}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default Filter
