import {ProgressSpinner} from 'primereact/progressspinner'
import {useEffect, useState} from 'react'
import text from '../../data/text.json'
import Site from '../../api/site'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {Controller, useForm} from 'react-hook-form'

const About = () => {
    const lang = 'az'
    const [isFetching, setIsFetching] = useState(true)
    const [loader, setLoader] = useState(false)
    const [info, setInfo] = useState(null)

    const {control, reset, handleSubmit} = useForm()

    const fetchData = async() => {
        setIsFetching(true)
        const data = await Site.getInfo()
        delete data?.created_at
        delete data?.updated_at
        delete data?.id
        setInfo(data)
        reset(data)
        setIsFetching(false)
    }

    const update = async(data) => {
        setLoader(true)
        await Site.updateInfo(data)
        setLoader(false)
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className="about-page w-full">
            {isFetching ? (
                <div className="flex mt-5 justify-content-center align-items-center">
                    <ProgressSpinner/>
                </div>
            ) : (
                <div className="content w-full">
                    <p className="page-title">{text[lang].about_page_title}</p>
                    <form onSubmit={handleSubmit(update)}>
                        <div className="grid w-full">
                            {info && Object.keys(info).map(item => (
                                <Controller control={control} render={({field: {value, onChange}}) => (
                                    <div className="col-12 col:md-6">
                                        <label htmlFor={item}>{item.charAt(0).toUpperCase() + item.slice(1)}</label>
                                        <InputText className="w-full" name={item} id={item} value={value}
                                            onChange={onChange}/>
                                    </div>
                                )} name={item}/>
                            ))}
                        </div>
                        <div className="grid">
                            <div className="col-12">
                                <div className="flex justify-content-end">
                                    <Button className="p-button-danger d-flex align-items-center gap-1"
                                        disabled={loader}>
                                        {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                                        {text[lang].save}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}

export default About
