import text from '../../data/text.json'
import {InputText} from 'primereact/inputtext'
import {Controller, useForm} from 'react-hook-form'
import {Button} from 'primereact/button'
import {useState} from 'react'
import Users from '../../api/users'
import classNames from 'classnames'

const WorkerForm = ({setModal, modal, fetchUsers}) => {
    const lang = 'az'
    const [loader, setLoader] = useState(false)
    const {control, handleSubmit, formState:{errors}}  = useForm()
    const [passwordIsShow, setPasswordIsShow] = useState(false)

    const create = async data => {
        setLoader(true)
        try {
            await Users.updatePassword({
                ...data,
                id:modal?.id
            })
            fetchUsers()
            setModal(null)
        } catch (e) {
            console.log('Error')
        } finally {
            setLoader(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(create)} className="grid w-full">
            <Controller rules={{
                required:true
            }} control={control} render={({field:{value, onChange}}) => (
                <div className="col-12">
                    <label htmlFor="password">{text[lang].password}</label>
                    <span className="p-input-icon-right w-full">
                        <i onClick={() => setPasswordIsShow(!passwordIsShow)} className={classNames({
                            'pi cursor-pointer':true,
                            'pi-eye':!passwordIsShow,
                            'pi-eye-slash':passwordIsShow
                        })} />
                        <InputText
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            id="password"
                            aria-describedby="password"
                            placeholder={text[lang].password}
                            type={passwordIsShow ? 'text' : 'password'}
                        />
                    </span>
                    {errors.password && (
                        <small className="form-element-is-invalid">
                            {text[lang].field_required}
                        </small>
                    )}
                </div>
            )} name="password"/>
            <div className="col-12">
                <div className="flex justify-content-end gap-2">
                    <Button className="p-button-outlined p-button-sm" onClick={e => {
                        e.preventDefault()
                        setModal(null)
                    }}>{text[lang].close}</Button>
                    <Button className="p-button-danger p-button-sm" disabled={loader}>
                        {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                        {text[lang].save}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default WorkerForm
