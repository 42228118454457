import {useEffect, useState} from 'react'
import {ProgressSpinner} from 'primereact/progressspinner'
import text from '../../data/text.json'
import Site from '../../api/site'
import {Controller, useForm} from 'react-hook-form'
import {InputText} from 'primereact/inputtext'
import {Card} from 'primereact/card'
import {TreeSelect} from 'primereact/treeselect'
import {Button} from 'primereact/button'
import {TreeTable} from 'primereact/treetable'
import {Column} from 'primereact/column'
import ServiceModal from './serviceModal'
import {Dialog} from 'primereact/dialog'

const Service = () => {
    const [isFetching, setIsFetching] = useState(false)
    const [services, setServices] = useState([])
    const [loader, setLoader] = useState(false)
    const [delLoader, setDelLoader] = useState(false)
    const [formIsActive, setFormIsActive] = useState(false)
    const lang = 'az'

    const {control, reset, handleSubmit} = useForm()

    const create = async(data) => {
        setLoader(true)
        try {
            await Site.addService(data)
            fetchData()
            reset({
                name:''
            })
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }

    const deleteService = async(id) => {
        setDelLoader(id)
        await Site.deleteService(id)
        fetchData()
        setDelLoader(id)
    }

    const getChildren = data => {
        return data?.map(item => {
            return {
                ...item,
                key:item?.value,
                children:getChildren(item?.children)
            }
        })
    }

    const fetchData = async() => {
        setIsFetching(true)
        const data = await Site.getServices()
        setServices(data?.map(item => {
            return {
                ...item,
                key:item?.value,
                children:getChildren(item?.children)
            }
        }))
        setIsFetching(false)
    }

    const getTableChildren = data => {
        return data?.map(item => {
            return {
                key:item?.value,
                data:{
                    label:item?.label,
                    buttons:<div className="flex gap-1">
                        <Button className="p-button-success p-button-sm" onClick={() => setFormIsActive(item)}>
                            <i className="pi pi-pencil"/>
                        </Button>
                        <Button className="p-button-danger p-button-sm" onClick={() => deleteService(item?.value)}>
                            {item?.value === delLoader ? <i className="pi pi-spin pi-spinner"/> : <i className="pi pi-trash"/>}
                        </Button>
                    </div>
                },
                children:getTableChildren(item?.children)
            }
        })
    }

    const tableData = services?.map(item => {
        return {
            key:item?.key,
            data:{
                label:item?.label,
                buttons:<div className="flex gap-1">
                    <Button className="p-button-success p-button-sm" onClick={() => setFormIsActive(item)}>
                        <i className="pi pi-pencil"/>
                    </Button>
                    <Button className="p-button-danger p-button-sm" onClick={() => deleteService(item?.value)}>
                        {item?.value === delLoader ? <i className="pi pi-spin pi-spinner"/> : <i className="pi pi-trash"/>}
                    </Button>
                </div>
            },
            children:getTableChildren(item?.children)
        }
    })

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="about-page w-full">
            {isFetching ? (
                <div className="flex mt-5 justify-content-center align-items-center">
                    <ProgressSpinner/>
                </div>
            ) : (
                <div className="content w-full">
                    <p className="page-title">{text[lang].service_page_title}</p>
                    <Dialog
                        header={text[lang].service_page_title}
                        visible={formIsActive}
                        draggable={false}
                        onHide={() => setFormIsActive(null)}
                        style={{width: '50vw'}} breakpoints={{'960px': '75vw', '641px': '100vw'}}>
                        <ServiceModal formIsActive={formIsActive} setFormIsActive={setFormIsActive} fetchData={fetchData} services={services}/>
                    </Dialog>
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <Card>
                                <form onSubmit={handleSubmit(create)}>
                                    <div className="grid">
                                        <div className="col-12 md:col-6">
                                            <label htmlFor="name">{text[lang].name}</label>
                                            <Controller render={({field:{value, onChange}}) => (
                                                <InputText
                                                    className="w-full"
                                                    value={value}
                                                    onChange={onChange}
                                                    id="name"
                                                    aria-describedby="name"
                                                    placeholder={text[lang].name}
                                                />
                                            )} name="name" control={control}/>
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <Controller control={control} render={({field:{value, onChange}}) => (
                                                <>
                                                    <label htmlFor="note">{text[lang].parent_service}</label>
                                                    <TreeSelect
                                                        id="parent_id"
                                                        value={value}
                                                        onChange={onChange}
                                                        options={services}
                                                        name="parent_id"
                                                        selectionMode="single"
                                                        className="w-full" />
                                                </>
                                            )} name="parent_id"/>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12">
                                            <div className="mt-2 flex justify-content-end">
                                                <Button disabled={loader} className="p-button-danger">
                                                    {loader ? <i className="pi pi-spin pi-spinner mr-1"/> : text[lang].save}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <TreeTable value={tableData}>
                                <Column key="label" field="label" header={text[lang].name} expander/>
                                <Column field="buttons"/>
                            </TreeTable>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Service
