import {useEffect, useState} from 'react'
import Users from '../../../api/users'
import {ProgressSpinner} from 'primereact/progressspinner'
import text from '../../../data/text.json'
import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import {Badge} from 'primereact/badge'

const PatientInfo = ({patient}) => {
    const [isFetching, setIsFetching] = useState(false)
    const [data, setData] = useState([])

    const lang = 'az'

    const statueses = {
        '-1': 'danger',
        '-2': 'danger',
        '1': 'warning',
        '2': 'success'
    }

    const fetchData = async() => {
        setIsFetching(true)
        const data = await Users.patientReports({
            patient_id: patient?.patient_id,
            doctor_id: patient?.doctor_id
        })
        setData(data?.map(item => {
            return {
                ...item,
                doctor: `${item?.doctor_name} ${item?.doctor_surname} ${item?.doctor_father}`,
                doctor_duty: item?.doctor_duty_label,
                start_date: item?.start_date,
                end_date: item?.end_date,
                status: <Badge value={item?.status_label} severity={statueses[item.status]}/>
            }
        }))
        setIsFetching(false)
    }

    useEffect(() => {
        if (patient) {
            fetchData()
        }
    }, [patient])

    return isFetching ? (
        <div className="flex justify-content-center align-items-center">
            <ProgressSpinner/>
        </div>
    ) : (
        <div>
            <div className="font-bold">
                {patient?.name} {patient?.surname} ({data?.length})
            </div>
            <DataTable emptyMessage={text[lang].no_info}
                className="mt-5" value={data}>
                <Column field="doctor" header={text[lang].doctor} sortable/>
                <Column field="doctor_duty" header={text[lang].position} sortable/>
                <Column field="end_date" header={text[lang].end_date} sortable/>
                <Column field="service_name" header={text[lang].service} sortable/>
                <Column field="price" header={text[lang].price} sortable/>
                <Column field="price_pay" header={text[lang].price_pay} sortable/>
                <Column field="status" header={text[lang].status} sortable/>
                <Column field="insurance" header={text[lang].insurance} sortable/>
                <Column field="note" header={text[lang].note} sortable/>
            </DataTable>
        </div>
    )
}

export default PatientInfo
