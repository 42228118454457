import Workers from './views/workers'
import Patients from './views/patients'
import Profile from './views/profile'
import DoctorPatients from './views/patients/DoctorPatients'
import About from './views/about'
import Carousel from './views/carousel'
import Gallery from './views/gallery'
import Service from './views/service'
import Main from './views/main'
import Dashboard from './views/dashboard'
import Doctors from './views/dashboard/doctors'
import PatientsStatistics from './views/dashboard/patients'

const routeItem = (title, path, component, icon, roles, isSite = false, show = true) => {
    return {
        title,
        path,
        component,
        icon,
        roles,
        isSite,
        show
    }
}

const routes = {
    main: routeItem('ThreeSteps', '/', <Main />, <i className="pi pi-user" />, null, false, false),
    dashboard: routeItem('Statistikalar', '/dashboard', <Dashboard />, <i
        className="pi pi-chart-bar" />, [1, 4], false, true),
    doctors_statistics: routeItem('Statistikalar', '/dashboard/doctors', <Doctors />, null, [1, 4], false, false),
    patient_statistics: routeItem('Statistikalar', '/dashboard/patients',
        <PatientsStatistics />, null, [1, 4], false, false),
    profile: routeItem('Profil', '/profile', <Profile />, <i className="pi pi-user" />),
    workers: routeItem('İşçilər', '/workers', <Workers />, <i className="pi pi-users" />, [1, 4]),
    patients: routeItem('Xəstələr', '/patients', <Patients />, <i className="pi pi-calendar" />),
    doctor_patients: routeItem('Xəstələr', '/patients/:id', <DoctorPatients />, <i className="pi pi-calendar" />),
    about: routeItem('Əlaqə', '/about', <About />, <i className="pi pi-info-circle" />, [1, 4], true),
    carousel: routeItem('Karusel', '/carousel', <Carousel />, <i className="pi pi-images" />, [1, 4], true),
    gallery: routeItem('Qalereya', '/gallery', <Gallery />, <i className="pi pi-images" />, [1, 4], true),
    services: routeItem('Servislər', '/services', <Service />, <i className="pi pi-clone" />, [1, 4], true)
}

const routeArr = Object.values(routes)
export {
    routes,
    routeArr
}
